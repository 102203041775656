import TimelineMax from "gsap/TimelineMax";
import TweenMax from "gsap/TweenMax";
import {Power0} from "gsap/EasePack";

import AnimationHelper from "./animationhelper";

const animationhelper = new AnimationHelper();

export const TLscene1 = () => {
    return new TimelineMax()
        .add(animationhelper.visualClipTween('.js-scene-culture-visual'))
        .add(animationhelper.visualInTween('.js-scene-culture-visual-image'), "-=.5")
        .add(animationhelper.visualOutTween('.js-scene-intro-visual-image'), "-=.5")
        .add(animationhelper.slashInTween('.js-scene-culture-slash'), "-=.2")
};
export const TLscene2 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-culture-slash'))
    .add(animationhelper.visualClipTween('.js-scene-agency-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-agency-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-culture-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-agency-slash'), "-=.2")

};
export const TLscene3 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-agency-slash'))
    .add(animationhelper.visualClipTween('.js-scene-academy-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-academy-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-agency-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-academy-slash'), "-=.2")
};
export const TLscene4 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-academy-slash'))
    .add(animationhelper.visualClipTween('.js-scene-soup-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-soup-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-academy-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-soup-slash'), "-=.2")
};
export const TLscene5 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-soup-slash'))
    .add(animationhelper.visualClipTween('.js-scene-skisurf-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-skisurf-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-soup-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-skisurf-slash'), "-=.2")

};
export const TLscene6 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-skisurf-slash'))
    .add(animationhelper.visualOutTween('.js-scene-skisurf-visual-image'), "-=.5")
    .add(animationhelper.visualClipTween('.js-scene-ski-video'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-ski-video-video'), "-=.5")
};
export const TLscene6b = () => {
    return new TimelineMax()
    .add(animationhelper.visualOutTween('.js-scene-ski-video-video'))
    .add(animationhelper.visualClipTween('.js-scene-surf-video'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-surf-video-video'), "-=.5")

};
export const TLscene7 = () => {
    return new TimelineMax()
    .add(animationhelper.visualOutTween('.js-scene-surf-video-video'))
    .add(animationhelper.slashInTween('.js-scene-jobs-slash'), "-=.2")

};
export const TLscene8 = () => {
    let insightTween = TweenMax.fromTo('.js-scene-insight-slash', .5, {
        x: '-100%'
    }, {
        x: '0%',
        ease: Power0.easeNone
    });

    return new TimelineMax()
        .add(insightTween);
};


// this.scene8 = new ScrollMagic.Scene({
//     triggerElement: '.js-scene-contact', 
//     duration: '100%', 
//     triggerHook: 'onEnter'
// })
// .setTween(TLscene8)
// .addTo(_this.controller);

// this.scroll.options.scenes.push(this.scene8);

