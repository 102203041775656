import TweenMax from "gsap/TweenMax";
import {Power3,Power4} from "gsap/EasePack";
import debounce from "../../xx_shame/debounce";

export default class Slash {
    constructor(options = {}) {

        this.options = Object.assign({
            element: "body",
            onAnimateInBegin: (e) => {
            },
            onAnimateInEnd: (e) => {
            },
            onAnimateOutBegin: (e) => {
            },
            onAnimateOutEnd: (e) => {
            }

        }, options);

        this.el = document.querySelector(this.options.element);
        this.DOM = {
            svg: null,
            shape: null
        };

        this.visible = false;
        this.settings = {
            animation: {
                duration: .5,
                easing: {
                    in: Power4.easeOut,
                    out: Power3.easeOut
                },
                pointDelay: .02
            },
            fill: "#000000"
        };
    }

    init() {
        this.createSlash();
        this.initEvents();
    }

    initEvents() {
        window.addEventListener(
            "resize",
            debounce(() => {
                this.rect = this.el.getBoundingClientRect();
                this.updateSlash();
            }, 20)
        );


        this.rect = this.el.getBoundingClientRect();
        this.updateSlash();
    }

    createSlash() {
        this.rect = this.el.getBoundingClientRect();
        this.frameSize = this.rect.width / 12;
        this.paths = {
            initial: this.calculatePath("initial"),
            final: this.calculatePath("final")
        };
        // this.DOM.svg = document.createElementNS(
        // 	"http://www.w3.org/2000/svg",
        // 	"svg"
        // );
        // this.DOM.svg.setAttribute("class", "c-slash js-slash");
        // this.DOM.svg.setAttribute("width", "100%");
        // this.DOM.svg.setAttribute("height", "100%");
        // this.DOM.svg.setAttribute("display", "none");
        // this.DOM.svg.setAttribute(
        // 	"viewbox",
        // 	`0 0 ${this.rect.width} ${this.rect.height}`
        // );
        // this.DOM.svg.innerHTML = `
        //           <path fill="${this.settings.fill}" d="${
        // 	this.paths.initial
        // }"/>`;

        // this.el.appendChild(this.DOM.svg);
        //this.el.parentNode.insertBefore(this.DOM.svg, this.el.nextSibling);
        this.DOM.svg = document.querySelector('.js-slash');
        this.DOM.shape = this.DOM.svg.querySelector("path");

        TweenMax.set(this.DOM.shape, {
            attr: {points: this.paths.initial}
        });
    }

    updateSlash() {
        this.paths.initial = this.calculatePath("initial");
        this.paths.final = this.calculatePath("final");
        this.DOM.svg.setAttribute(
            "viewbox",
            `0 0 ${this.rect.width} ${this.rect.height}`
        );
        this.DOM.shape.setAttribute(
            "d",
            this.visible ? this.paths.final.step4 : this.paths.initial
        );
    }

    calculatePath(path = "initial") {

        if (path === 'initial') {
            return `M 0,0 0,${this.rect.height} ${this.rect.width},${this.rect.height} ${this.rect.width},0 0,0 Z M 0,0 ${this.rect.width},0 ${this.rect.width},${this.rect.height} 0,${this.rect.height} Z`;
        } else {


            //let sm = this.rect.height * .05;
            let sm = 60;

            let ratio = .2;

            let sheight = this.rect.height - sm * 2;
            let swidth = sheight * ratio;


            const point1 = {
                x: (this.rect.width * .5 - swidth * .5) - Math.tan(0.3490658504) * ((this.rect.height * .5) - sm),
                y: sm
            };
            const point2 = {
                x: (this.rect.width * .5 + swidth * .5) - Math.tan(0.3490658504) * ((this.rect.height * .5) - sm),
                y: sm
            };
            const point3 = {
                x: this.rect.width - point2.x,
                y: this.rect.height - point2.y
            };
            const point4 = {
                x: this.rect.width - point1.x,
                y: this.rect.height - point1.y
            };


            return {
                step1: `M 0,0 0,${this.rect.height} ${this.rect.width},${this.rect.height} ${this.rect.width},0 0,0 Z M ${point1.x},${point1.y} ${this.rect.width},0 ${this.rect.width},${this.rect.height} 0,${this.rect.height} Z`,
                step2: `M 0,0 0,${this.rect.height} ${this.rect.width},${this.rect.height} ${this.rect.width},0 0,0 Z M ${point1.x},${point1.y} ${point2.x},${point2.y} ${this.rect.width},${this.rect.height} 0,${this.rect.height} Z`,
                step3: `M 0,0 0,${this.rect.height} ${this.rect.width},${this.rect.height} ${this.rect.width},0 0,0 Z M ${point1.x},${point1.y} ${point2.x},${point2.y} ${point4.x},${point4.y} 0,${this.rect.height} Z`,
                step4: `M 0,0 0,${this.rect.height} ${this.rect.width},${this.rect.height} ${this.rect.width},0 0,0 Z M ${point1.x},${point1.y} ${point2.x},${point2.y} ${point4.x},${point4.y} ${point3.x},${point3.y} Z`
            }


        }

    }

    animateIn(cb = {}) {


        this.cb = Object.assign({
            onSlashComplete: (e) => {
            }

        }, cb);

        let _this = this;

        if (this.isAnimating) return false;
        if (this.visible) return false;
        this.isAnimating = true;
        this.visible = true;
        this.options.onAnimateInBegin();


        this.DOM.svg.setAttribute("display", "block");


        TweenMax.to('.js-view', .5, {
            opacity: .6,
            //scale: .9,
            delay: .05,
            ease: this.settings.animation.easing.in
        });


        // TweenMax.set('.js-view-content',{
        // 	opacity: 0
        // });

        TweenMax.to('.js-view-content', .5, {
            opacity: 0,
            ease: this.settings.animation.easing.in
        });


        TweenMax.to(this.DOM.shape, .6, {
            morphSVG: {
                d: this.paths.final.step4
            },
            ease: this.settings.animation.easing.in,
            onComplete: () => {
                _this.isAnimating = false;
                _this.options.onAnimateInEnd();
                _this.cb.onSlashComplete();
            },
            z: 0.1
        });


    }

    animateOut() {


        if (this.isAnimating) return false;
        if (!this.visible) return false;
        this.isAnimating = true;
        this.options.onAnimateOutBegin();

        this.visible = false;


        TweenMax.to('.js-view', .5, {
            opacity: 1,
            scale: 1,
            delay: .05,
            ease: this.settings.animation.easing.out
        });

        TweenMax.to('.js-view-content', .5, {
            opacity: 1,
            ease: this.settings.animation.easing.out
        });

        TweenMax.to(this.DOM.shape, 0.4, {
            morphSVG: {
                d: this.paths.initial
            },
            delay: 0.25,
            onComplete: () => {
                this.isAnimating = false;
                this.options.onAnimateOutEnd();
            },
            z: 0.1
        });


    }
}