import {history} from "../xx_shame/history";
import SYS from "../config";

export default class View {

    constructor(options) {
        this.options = Object.assign({
            containerSelector: '.js-view-container',
            onAnimateInBegin: () => {
            },
            onAnimateInEnd: () => {
            },
            onAnimateOutBegin: () => {
            },
            onAnimateOutEnd: () => {
            },
            path: null,
            bodyClass: null,
            usesHistory: false
        }, options);

        this.name = this.options.name;
        this.el = document.querySelector(this.options.containerSelector);

        this.isVisible = false;


        this.checkPathname = this.checkPathname.bind(this);
        this.historyListener = this.historyListener.bind(this);

        // if (this.options.usesHistory) {
        //     history.addListener(this.historyListener);
        // }

    }

    async historyListener(location, action) {

        if (this.checkPathname()) {

            //await this._populate();
            await this._animateIn();

        } else if (this.isVisible) {
            await this._animateOut();
        }

    }


    async init() {

        if (this.checkPathname()) {

            await this._populate();
            await this._animateIn();

        } else if (this.isVisible) {
            await this._animateOut();
        }
    }

    checkPathname() {
        return this.options.path instanceof RegExp && this.options.path.test(window.location.pathname);
    }

    _populate() {
        return new Promise((resolve) => {
                (async () => {
                    if (this.populate) {
                        await this.populate();
                    }
                    resolve();
                })();
            }
        );
    }

    _animateIn() {
        return new Promise((resolve) => {

            (async () => {

                    if (!this.isVisible) {

                        this.isVisible = true;
                        this.el.classList.remove("is-hidden");

                        if (this.animateIn) {
                            await this.animateIn();
                        }
                    }

                    resolve();
                })();
            }
        );
    }

    _animateOut() {
        return new Promise((resolve) => {
            (async () => {
                if (this.isVisible) {
                    this.isVisible = false;


                    if (this.animateOut) {
                        await this.animateOut();
                    }
                    this.el.classList.add("is-hidden");

                }

                resolve();
            })();
        });
    }

    async pushTo(view) {
        //await this._animateOut();
        //history.pushState(view, {state: view, source: window.location.pathname}, SYS.title);
    }
}