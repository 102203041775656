const spanWrapNewLines = (elements) => {

    Array.from(elements).forEach((element) => {
        const _arrWords = element.innerHTML.split(' ');

        let _textWithWordSpans = '';
        _arrWords.forEach((el, i) => {
            _textWithWordSpans += '<span>' + _arrWords[i] + '</span> ';
        });
        element.innerHTML = _textWithWordSpans;

        const _allSpans = Array.from(element.querySelectorAll('span'));
        let _prevOffsetTop = _allSpans[0].offsetTop;

        let _textWithLineSpans = '<span class="u-block@l">';
        _allSpans.forEach((el) => {
            if (el.offsetTop > _prevOffsetTop) {
                _textWithLineSpans += '</span><span class="u-block@l">';
            }
            _textWithLineSpans += el.innerHTML + ' ';

            _prevOffsetTop = el.offsetTop;
        });
        _textWithLineSpans += '</span>';

        element.innerHTML = _textWithLineSpans;

    });
};

export {spanWrapNewLines}



const spanWrapAllWords = (elements) => {

    Array.from(elements).forEach((element) => {
        const _arrWords = element.innerHTML.split(' ');

        let _textWithWordSpans = '';
        _arrWords.forEach((el, i) => {
            _textWithWordSpans += '<span class="u-inline-block@l u-skew-back@l">' + _arrWords[i] + '</span> ';
        });
        element.innerHTML = _textWithWordSpans;

    });
};

export {spanWrapAllWords}