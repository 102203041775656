import anime from "animejs";
import superagent from "superagent";
import $ from "jquery";
import TweenMax from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import TimelineMax from 'gsap/TimelineMax';
import {Power0} from "gsap/EasePack";

import View from "../../00_fundaments/View";

import Video from "../../02_molecules/Video/Video";
import Scroll from "../../02_molecules/Scroll/Scroll";
import Nearby from "../../00_helpers/nearby";

import * as texthelpers from "../../00_helpers/texthelper";

export default class CaseDetail extends View {
    constructor(options = {}) {

        options = Object.assign({
            containerSelector: '.js-view-detail',
            titleSelector: '.js-detail-title',
            videoSelector: '.js-play-video',
            onClose: () => {
            }
        }, options);

        super(options);

        this.el = document.querySelector(this.options.containerSelector);

        this.settings = {
            animation: {
                duration: 500,
                easing: {
                    in: 'cubicBezier(.77, 0, .175, 1)',
                    out: 'easeInQuint'
                }
            }
        };

        this.scenes = [];
        this.SMscenes = [];
    }

    init() {
        super.init();
    }

    initEvents() {


        $('.js-case-detail-back').on('click', (e) => {
            e.preventDefault();
            this.options.onClose(this);
        });

        $('.js-show-insight').on('click', (e) => {
            e.preventDefault();

            $('html, body').stop().animate({
                scrollTop: $('.js-insight').eq(0).offset().top
            }, 500);

        });


        new Scroll();

        this.createScenes();


        $('.js-play-video').on('click', (e) => {

            this.createVideo(e.currentTarget.dataset.video);
            this.initVideo();
        });

        (function(that) {
            $('.js-case-detail-back').on('click', function(e) {

                e.preventDefault();

                var thisHREF = $(this).attr('href');

                that.animateOut().then(function() { window.location = thisHREF; });

            });

            $(window).on('resize', function() {

                clearTimeout(that.resizeTimer);
                that.resizeTimer = setTimeout(function() { that.refreshScenes(); }, 200);

            });
        })(this);


    }

    refreshScenes() {

        this.SMscenes.forEach((scene, index) => {

            scene.refresh();

        });

    }

    populate() {

        return new Promise( (resolve) => {

            (async () => {

                this.initEvents();
                resolve();

            })();
        });

    }

    async animateIn() {


        this.options.onAnimateInBegin();

        return new Promise((resolve) => {


            $('body').addClass('case-detail-is-visible detail-is-visible');


            anime({
                targets: '.js-transition',
                duration: this.settings.animation.duration,
                easing: this.settings.animation.easing.in,
                delay:400,
                translateX: [0, '100%'],
                complete: () => {

                    this.options.onAnimateInEnd();

                    resolve();

                    anime({
                        targets: '.js-button-play-anim',
                        duration: 1000,
                        easing: 'easeOutElastic',
                        complete: () => {
                            if($('.js-button-play-anim').length > 0) this.videoNearby();
                        },
                        delay: 500,
                        scale: [1.5, 1],
                        opacity: [0, 1]
                    });

                }
            });

            if (window.innerWidth >= 1100) {
                anime({
                    targets: '.js-detail-content, .js-scene-detail-slash',
                    duration: this.settings.animation.duration,
                    easing: this.settings.animation.easing.in,
                    translateX: [100, 0]
                });
            }


            texthelpers.spanWrapAllWords(document.querySelectorAll(this.options.titleSelector));
            texthelpers.spanWrapAllWords(document.querySelectorAll('.js-block-text p'));

        });
    }


    animateOut() {

        $('body').addClass('detail-is-animating');

        this.options.onAnimateOutBegin();

        return new Promise((resolve) => {
            anime({
                targets: '.js-transition',
                duration: this.settings.animation.duration,
                easing: this.settings.animation.easing.in,
                translateX: ['-100%', 0],
                complete: () => {

                    this.options.onAnimateOutEnd();
                    resolve();
                }
            });
        });

    }


    createScenes() {

        this.controller = new ScrollMagic.Controller({
            refreshInterval: 0
        });


        /**
         *
         * Scene 1
         *
         */

        let TLscene1 = new TimelineMax();

        let insightTween = TweenMax.fromTo('.js-scene-detail-insight-slash', .5, {
            x: '-100%'
        }, {
            x: '0%',
            ease: Power0.easeNone
        });

        TLscene1.add(insightTween);


        let darkenTween = TweenMax.to('.js-scene-detail-darken', .5, {
            opacity: 1,
            ease: Power0.easeNone
        });

        TLscene1.add(darkenTween, "-=.5");


        this.scene1 = new ScrollMagic.Scene({
            duration: '100%',
            triggerHook: 'onEnter'
        })
        .setTween(TLscene1)
        .addTo(this.controller);

        this.scene1.triggerElement($('.js-insight')[0]);

        this.SMscenes.push(this.scene1);

    }


    createVideo(video) {
        $('.js-video-overlay').html('<video class="js-video video-js" controls="" preload="auto" data-video="' + video + '"></video>');
    }


    initVideo() {


        this.video = new Video({
            element: '.js-video',
            parentElement: '.js-video-overlay',
            onReady: () => {
                $('.js-video-overlay').addClass('is-visible');

                TweenMax.to('.js-video-overlay', .5, {
                    opacity: 1
                });
            },
            onClose: () => {
                TweenMax.to('.js-video-overlay', .5, {
                    opacity: 0,
                    onComplete: () => {
                        this.video.player.dispose();
                        $('.js-video-overlay').removeClass('is-visible');
                    }
                });
            }

        });


    }


    videoNearby() {
        const lineEq = (y2, y1, x2, x1, currentVal) => {
            // y = mx + b
            var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
            return m * currentVal + b;
        };

        const darkenEl = document.querySelector('.js-video-darken');
        const buttonCtrl = document.querySelector('.js-play-video-desktop');
        const videoEl = document.querySelector('.js-detail-visual');
        const distanceThreshold = {min: 0, max: window.innerWidth / 4};
        const scaleInterval = {from: 1, to: 1.05};

        const opacityInterval = {from: 0, to: 1};

        new Nearby(buttonCtrl, {
            onProgress: (distance) => {
                const tx = lineEq(scaleInterval.from, scaleInterval.to, distanceThreshold.max, distanceThreshold.min, distance);
                TweenMax.to(videoEl, .5, {
                    ease: 'Expo.easeOut',
                    scale: Math.max(tx, scaleInterval.from)
                });

                const o = lineEq(opacityInterval.from, opacityInterval.to, distanceThreshold.max, distanceThreshold.min, distance);
                TweenMax.to(darkenEl, .5, {
                    ease: 'Expo.easeOut',
                    opacity: Math.max(o, opacityInterval.from)
                });
            }
        });


    }


}
