import $ from "jquery";
import lozad from 'lozad';
import anime from "animejs";
import TweenMax from 'gsap/TweenMax';


import {store} from '../../redux/store';

import Slider from '../../02_molecules/Slider/Slider';
import View from '../../00_fundaments/View';


export default class Cases extends View {

    constructor(options = {}) {

        options = Object.assign({
            onCaseLoaded: () => {
            }
        }, options);


        super(options);

        this.container = document.querySelector(this.options.containerSelector);

        this.settings = {
            animation: {
                duration: 500,
                easing: {
                    in: 'cubicBezier(.77, 0, .175, 1)',
                    out: 'easeInQuint'
                }
            }
        };

        this.filterByClientId = false;


    }

    init() {

        super.init();
        //this.allCases = this.container.querySelectorAll('.js-slider-item');

        let _this = this;

        $('body').css({'overflow':'hidden'});

        window.addEventListener('resize', function () {
            // TODO: add debounce
            _this.updatePath();
        });

        this.updatePath();

        store.subscribe(() => {
            this._onStoreStateChange();
        });

        (function(that) {
            $('.js-case-deeplink').on('click', function(e) {

                e.preventDefault();

                var thisHREF = $(this).attr('href');

                that.animateOut().then(function() { window.location = thisHREF; });

            });
        })(this);

        this.loadImages();


    }

    _onStoreStateChange() {
        // get store state and trigger dom change on both selectors set

        this.populate();

    }

    populate() {
        if (store.getState().selectors.clients) {
            let _clientsFilterState = store.getState().selectors.clients;
            this.filterByClientId = _clientsFilterState.id;
            if (this.slider) {
                this.slider.update(this.filterByClientId ? this.filterByClientId : null);
            }
        }
    }

    animateIn() {


        this.options.onAnimateInBegin();

        return new Promise((resolve) => {


            if (!this.slider) {

                this.slider = new Slider({
                    filterParameter: 'data-client-id',
                    onItemSelect: async (_caseId, _slug) => {


                        // Load case detail

                        if (this.isLoading) return false;
                        this.isLoading = true;


                        await this.pushTo(`/cases/${_caseId}/${_slug}`);

                        this.isLoading = false;
                    }
                });
            }

            this.slider.allowScroll = true;
            this.slider.update(this.filterByClientId ? this.filterByClientId : null);

            resolve();

            this.options.onAnimateInEnd();
        });


    }

    animateOut() {

        this.slider.allowScroll = false;


        this.options.onAnimateOutBegin();

        return new Promise((resolve) => {
            anime({
                targets: '.js-transition',
                duration: this.settings.animation.duration,
                easing: this.settings.animation.easing.in,
                translateX: ['-100%', 0],
                complete: () => {

                    this.options.onAnimateOutEnd();
                    resolve();
                }
            });
        });
    }

    loadImages() {


        this.observer = lozad('.js-lozad', {
            threshold: 0.1,
            loaded: function (el) {
                el.classList.add('is-loaded');
            }

        });


        this.observer.observe();

    }


    calculateClipPath() {

        this.rect = this.container.getBoundingClientRect();

        //let sm = this.rect.height * .05;
        let sm = 60;

        let ratio = .2;

        let sheight = this.rect.height - sm * 2;
        let swidth = sheight * ratio;


        const point1 = {
            x: (this.rect.width * .5 - swidth * .5) - Math.tan(0.3490658504) * ((this.rect.height * .5) - sm),
            y: sm
        };
        const point2 = {
            x: (this.rect.width * .5 + swidth * .5) - Math.tan(0.3490658504) * ((this.rect.height * .5) - sm),
            y: sm
        };
        const point3 = {
            x: this.rect.width - point2.x,
            y: this.rect.height - point2.y
        };
        const point4 = {
            x: this.rect.width - point1.x,
            y: this.rect.height - point1.y
        };

        return `polygon(${point1.x}px ${point1.y}px,${point2.x}px ${point2.y}px, ${point4.x}px ${point4.y}px, ${point3.x}px ${point3.y}px)`;

    }

    updatePath() {
        TweenMax.set('.js-cases-slash', {
            clipPath: this.calculateClipPath()
        });
    }


}
