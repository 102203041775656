import anime from "animejs";
import $ from "jquery";
import TweenMax from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import TimelineMax from 'gsap/TimelineMax';
import {Power0, Sine} from "gsap/EasePack";
import superagent from 'superagent';

import View from '../../00_fundaments/View';

import Scroll from "../../02_molecules/Scroll/Scroll";

import AnimationHelper from "../../00_helpers/animationhelper";
import Sequencer from '../../xx_shame/sequencer';

import * as texthelpers from "../../00_helpers/texthelper";

import * as JobDetailScenes from '../../00_helpers/jobdetailscenes';
import SceneFactory from '../../00_helpers/scenefactory';

export default class JobDetail extends View {
    constructor(options = {}) {

        options = Object.assign({
            containerSelector: '.js-view-detail',
            onAnimateInComplete: () => {
            },
            onAnimateOutComplete: () => {
            },
            titleSelector: '.js-job-detail-title',
            onClose: () => {
            }
        }, options);

        super(options);

        this.el = document.querySelector(this.options.containerSelector);

        this.settings = {
            animation: {
                duration: 500,
                easing: {
                    in: 'cubicBezier(.77, 0, .175, 1)',
                    out: 'easeInQuint'
                }
            }
        };

        this.scenes = [];
        this.TLscenes = [];
        this.SMscenes = [];
    }

    init() {
        super.init();
        this.animationhelper = new AnimationHelper();
    }

    initEvents() {

        let _this = this;

        this.SceneFactory = new SceneFactory();

        new Scroll();
        // this.scroll = new Scroll({
        //     element: '.js-view-detail .js-scrollbar'
        // });

        this.controller = new ScrollMagic.Controller({
            refreshInterval: 0
        });

        this.createScenes();

        const s = Sequencer.make({
            canvas: document.querySelector(".js-seq"),
            from: '../../../assets/build/graphics/01.jpg',
            to: '../../../assets/build/graphics/06.jpg',
            direction: "y",
            loop: 'loop',
            interval: 300,
            playMode: "auto",
            // playMode         : "auto",
            scaleMode: "cover",
            showLoadedImages: false,
            hiDPI: false,
            imageLoad: function (e) {
            },
            queueComplete: function (e) {
            }
        });

        s.size((window.innerWidth + 100), window.innerHeight);

        window.addEventListener('resize', function () {
            s.size((window.innerWidth + 100), window.innerHeight);
        });


        $('.js-job-detail-back').on('click', (e) => {
            e.preventDefault();
            _this.options.onClose();
        });

        (function(that) {

            $(window).on('resize', function() {

                clearTimeout(that.resizeTimer);
                that.resizeTimer = setTimeout(function() { that.refreshScenes(); }, 200);

            });
        })(this);

    }

    refreshScenes() {

        this.SMscenes.forEach((scene, index) => {

            scene.refresh();

        });

    }


    createScenes() {

        let _this = this;

        /**
         *
         * Scene 1
         *
         */

        let TLscene1 = new TimelineMax();

        let insightTween = TweenMax.fromTo('.js-scene-job-slash', .5, {
            x: '-100%'
        }, {
            x: '0%',
            ease: Sine.easeOut
        });

        TLscene1.add(insightTween, "-=.2");


        let darkenTween = TweenMax.to('.js-scene-detail-darken', .5, {
            opacity: 1,
            ease: Power0.easeNone
        });

        TLscene1.add(darkenTween, "-=.5");


        this.scene1 = new ScrollMagic.Scene({
            duration: '100%',
            triggerHook: 'onEnter'
        })
        .setTween(TLscene1)
        .addTo(_this.controller);

        this.scene1.triggerElement($('.js-scene-job-detail')[0]);

        _this.SMscenes.push(this.scene1);


        /**
         *
         * Scene 2
         *
         */

        this.TLscenes.push({TimeLineScene: JobDetailScenes.TLscene2(), element: '.js-scene-slashies'});

        this.TLscenes.forEach((scene, index) => {

            if (scene.TimeLineScene !== null) {

                _this.SMscenes.push(this.SceneFactory.createScene({
                    triggerElement: scene.element,
                    setTween: scene.TimeLineScene,
                    addTo: this.controller
                }));

            }

            // background visuals
            const currentScene = $(scene.element);

            if(currentScene.length) {

                const visual = currentScene.attr('data-visual');
                const s = new ScrollMagic.Scene({
                    duration: '100%',
                    triggerHook: 'onEnter'
                }).addTo(this.controller);

                //s.triggerElement(scene.element);

                s.on('enter', (e) => {
                    $('.' + visual).show();
                });

                s.on('leave', (e) => {
                    $('.' + visual).hide();
                });

                _this.SMscenes.push(s);

            }

        });

        /**
         *
         * Scene 3
         *
         */


        let TLscene3 = new TimelineMax();

        TLscene3.add(_this.animationhelper.slashOutTween('.js-scene-slashies-slash'));
        TLscene3.add(_this.animationhelper.visualClipTween('.js-scene-places-visual'), "-=.4");
        TLscene3.add(_this.animationhelper.visualInTween('.js-scene-places-visual-image'), "-=.5");
        TLscene3.add(_this.animationhelper.visualOutTween('.js-scene-slashies-visual-image'), "-=.5");
        TLscene3.add(_this.animationhelper.slashInTween('.js-scene-places-slash'), "-=.2");

        this.scene3 = new ScrollMagic.Scene({
            duration: '100%',
            triggerHook: 'onEnter'
        })
        .setTween(TLscene3)
        .addTo(_this.controller);

        this.scene3.triggerElement($('.js-scene-places')[0]);
        _this.SMscenes.push(this.scene3);

        (function(that) {
            $('.js-scene').each(function () {

                let currentScene = this;
                let visual = $(this).attr('data-visual');

                let scene = new ScrollMagic.Scene({
                    duration: '200%',
                    triggerHook: 'onEnter'
                }).addTo(_this.controller);

                scene.triggerElement(this);

                scene.on('enter', (e) => {
                    $('.' + visual).show();
                });

                scene.on('leave', (e) => {
                    $('.' + visual).hide();
                });

                that.SMscenes.push(scene);

            });
        })(this);


    }


    async populate() {

    }

    async animateIn() {


        $('body').addClass('detail-is-animating');


        anime({
            targets: '.js-transition',
            duration: this.settings.animation.duration,
            easing: this.settings.animation.easing.in,
            translateX: ['-100%', 0],
            complete: () => {


                $('body').addClass('detail-is-visible').removeClass('detail-is-animating');

                this.initEvents();


                anime({
                    targets: '.js-transition',
                    duration: this.settings.animation.duration,
                    easing: this.settings.animation.easing.in,
                    translateX: [0, '100%'],
                    complete: () => {
                        this.options.onAnimateInComplete();


                    }
                });


                texthelpers.spanWrapAllWords(document.querySelectorAll(this.options.titleSelector));
                texthelpers.spanWrapAllWords(document.querySelectorAll('.js-job-block-text'));
            }
        });


    }


    async animateOut() {

        $('body').addClass('detail-is-animating').removeClass('detail-is-visible');
        this.options.onAnimateOutBegin();
        anime({
            targets: '.js-transition',
            duration: this.settings.animation.duration,
            easing: this.settings.animation.easing.in,
            translateX: ['100%', 0],
            complete: () => {

                this.options.onAnimateOutComplete();
                $('body').removeClass('detail-is-animating');

                anime({
                    targets: '.js-transition',
                    duration: this.settings.animation.duration,
                    easing: this.settings.animation.easing.in,
                    translateX: [0, '-100%'],
                    complete: () => { }
                });

            }
        });


    }


}
