import superagent from "superagent";

let getCases = (sector, challenge, callback) => {
    let _this = this;

    superagent
        .get('/api/cases')
        .query({ sector: sector })
        .query({ challenge: challenge })
        .then(function (res) {
            callback(res.body.data);
        });
}

export { getCases };