import { combineReducers, createStore } from "redux";
import { wizardReducer } from "./wizardreducer";
import { selectorReducer } from "./selectorreducer";

const reducers = combineReducers({
	wizard: wizardReducer,
    selectors: selectorReducer
});

const store = createStore(reducers,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export { store };
