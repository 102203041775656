import {Power0, Sine} from "gsap/EasePack";
import TweenMax from "gsap/TweenMax";

export default class AnimationHelper {

    visualClipTween(target) {

// let tween = TweenMax.to(target, .5, {
// 	 	"-webkit-clip-path":"polygon("+ (-0.364 * window.innerHeight) +"px 0px, "+window.innerWidth+"px 0px, " + (0.364 * window.innerHeight + window.innerWidth) + "px 100%, 0% 100%)",
// 	 	ease: Power0.easeNone
// 	 });

        let tween = TweenMax.to(target, .5, {
            opacity: 1,
            ease: Power0.easeNone
        });


        return tween;

    }


    visualInTween(target) {

        let tween = TweenMax.fromTo(target, .5, {
            x: '-50px'
        }, {
            x: '0px',
            ease: Power0.easeNone
        });

        return tween;

    }


    darkenOutTween(target) {

        let tween = TweenMax.to(target, .5, {
            opacity: 1,
            ease: Power0.easeNone
        });

        return tween;

    }


    visualOutTween(target) {

        let tween = TweenMax.to(target, .5, {
            x: '50px',
            ease: Power0.easeNone,
            autoAlpha: 0
        });

        return tween;

    }


    slashInTween(target) {

        let tween = TweenMax.fromTo(target, .5, {
            x: '-100%'
        }, {
            x: '0%',
            ease: Sine.easeOut
        });

        return tween;
    }


    slashOutTween(target) {

        let tween = TweenMax.to(target, .25, {
            opacity: 0,
            delay: .25,
            ease: Power0.easeNone
        });

        return tween;
    }
}