import anime from "animejs";
import superagent from "superagent";
import debounce from "../../xx_shame/debounce";
import lozad from 'lozad';
import imagesloaded from "imagesloaded";
import $ from "jquery";

import {store} from "../../redux/store";

import View from "../../00_fundaments/View";

import * as texthelpers from "../../00_helpers/texthelper";
import * as apihelper from "../../00_helpers/apihelper";


export default class Matrix extends View {
    constructor(options = {}) {

        options = Object.assign({
            elementSelector: ".js-view-results",
            thumbsSelector: ".js-results-thumbs",
            titlesSelector: ".js-results-titles",
            visualsSelector: ".js-results-visuals",
            thumbItemSelector: ".js-results-thumb",
            titleItemSelector: ".js-results-title",
            visualItemSelector: ".js-results-visual",
            triggerDetailSelector: ".js-trigger-detail",
            onCaseLoaded: () => {
            },
            onAnimateStart: () => {
            },
            autoStartTimerOnInit: true
        }, options);

        super(options);

        this.el = document.querySelector(this.options.elementSelector);

        this.titlesParent = document.querySelector(this.options.titlesSelector);
        this.thumbsParent = document.querySelector(this.options.thumbsSelector);
        this.visualsParent = document.querySelector(this.options.visualsSelector);

        this.isPopulated = false;
        this.freshPopulation = false;


        this.current = 0;
        this.currentId = null;

        this.orientation = 'desktop';


        this.isLoadingCase = false;

        this.settings = {
            timeout: 8000,
            animation: {
                duration: 600,
                easing: {
                    in: 'easeOutQuint',
                    out: 'easeOutBack'
                }
            }
        }
    }

    init() {
        super.init();
        this.timer = this.el.querySelector('.js-progress');

        store.subscribe(() => {
            this._onStoreStateChange();
        });

        $(document).scrollTop(0);
    }

    async _onStoreStateChange() {
        // get store state and trigger dom change on both selectors set

        let _wizardState = store.getState().wizard;

        if (_wizardState.showResults && this.checkPathname()) {

            // re-init the module to check for visability and if ok: populate with new data and animateIn
            await this._populate();
            await this._animateIn();
        }

    }

    populate() {

        return new Promise((resolve) => {

            let _wizardState = store.getState().wizard;

            if (_wizardState.showResults) {

                $('.js-main').addClass('is-loading');

                apihelper.getCases(_wizardState.sector, _wizardState.challenge, (cases) => {
                    this._renderResults(cases);
                    this.animateIn();


                    resolve();
                });
            }

        });
    }

    _renderResults(arrResults) {

        // reset possible current state
        this.freshPopulation = true;
        this.stopTimer();
        this.currentId = null;
        this.current = 0;

        let _titles = "";
        let _thumbs = "";
        let _visuals = "";

        arrResults.forEach((el, i) => {

            let _titleHtml =
                '<div class="c-wizard__title js-results-title" data-id="' +
                el.id +
                '">\n' +
                '    <div class="u-skew@l">\n' +
                '        <div><h1 class="u-text-white u-margin-bottom--l">' + el.title + "</h1></div>\n" +


                '        <div><h2 class="u-text-white u-skew-back@l u-margin-bottom--l c-wizard__client">' + el.client.name + "</h2></div>\n";

            if (el.summary) {
                _titleHtml += '        <div><p class="u-text-white u-skew-back@l u-no-margin c-wizard__summary">' + el.summary + "</p></div>\n";
            }

            _titleHtml += '        <div><a class="c-button u-margin-top--xl   js-trigger-detail u-skew u-no-skew@l" data-id="' + el.id + '" href="/cases/' + el.id + '/' + el.slug + '/"><span class="c-button__caption u-skew-back">More info</span></a></div>\n' +
                "    </div>\n" +
                "</div>";
            _titles += _titleHtml;

            let _thumbsParent =
                '<div data-id="' +
                el.id +
                '" class="c-wizard__thumb js-results-thumb" data-index="' + i + '">\n' +
                '    <div class="c-wizard__thumb-image js-lozad" style="background-image: url(' + el.cover + ')"></div>\n' +
                "</div>";
            _thumbs += _thumbsParent;

            let _visualsParent =
                '<div data-id="' +
                el.id +
                '" class="c-wizard__visual js-results-visual">\n' +
                '    <div class="c-wizard__visual-wrapper"><div class="c-wizard__visual-img js-lozad" style="background-image: url(' + el.cover + ')"></div></div>\n' +
                "</div>";
            _visuals += _visualsParent;
        });

        this.titlesParent.innerHTML = _titles;
        this.thumbsParent.innerHTML = _thumbs;
        this.visualsParent.innerHTML = _visuals;


        this.thumbs = Array.from(this.el.querySelectorAll(this.options.thumbItemSelector));
        this.titles = Array.from(this.el.querySelectorAll(this.options.titleItemSelector));
        this.visuals = Array.from(this.el.querySelectorAll(this.options.visualItemSelector));

        this.totalCases = this.titles.length;

        this.initEvents();

        if (this.options.autoStartTimerOnInit) this.initTimer();


        setTimeout(() => {
            this.thumbs[0].classList.add('is-active');
            this.titles[0].classList.add('is-active');
            this.visuals[0].classList.add('is-active');
        }, 50);


        this.isPopulated = true;

        //log(this.titlesParent.querySelectorAll('h1').constructor === NodeList);
    }


    initEvents() {

        //Array.from(this.thumbs.querySelectorAll(this.thumbItemSelector)).
        $(this.options.thumbItemSelector).on('click', (e) => {

            let _thumb = e.currentTarget;

            if (this.currentId == e.currentTarget.dataset.id) return false;
            //if (this.isAnimating || _thumb.dataset.index == this.current) return false;

            this.timerAnimation.restart();

            this.animateResults(_thumb.dataset.index);
        });


        $(this.options.triggerDetailSelector).on('click', (e) => {
            e.preventDefault();

            if (this.isLoadingCase) return false;

            this.isLoadingCase = true;
            e.currentTarget.classList.add('is-loading');
            this.el.classList.add('is-loading');


            this.stopTimer();


            let _caseId = e.currentTarget.dataset.id;

            let _this = this;

            superagent.get('/api/cases/' + _caseId).then(function (res) {
                let _caseData = res.body.data;
                if (_caseData) {

                    e.currentTarget.classList.remove('is-loading');
                    _this.el.classList.remove('is-loading');
                    _this.options.onCaseLoaded(_caseData);

                } else {
                    _this.isLoadingCase = false;
                    e.currentTarget.classList.remove('is-loading');
                }
            });

            /*superagent.get('/api/cases/' + _caseId).then(function (res) {
                let _caseData = res.body;
                if (_caseData) {
                    superagent.get('/partials/case_detail/' + _caseId + _query).then(function (res2) {
                        _this.isLoadingCase = false;
                        e.currentTarget.classList.remove('is-loading');
                        _this.el.classList.remove('is-loading');
                        _this.options.onCaseLoaded(_caseData, res2.text);
                    });
                } else {
                    _this.isLoadingCase = false;
                    e.currentTarget.classList.remove('is-loading');
                }
            });*/

        });


        window.addEventListener(
            "resize",
            debounce(() => {

                this.resizeHandler();
            }, 20)
        );

    }

    resizeHandler() {
        if (!this.options.autoStartTimerOnInit) return false;


        let o = this.orientation;

        if (window.innerWidth > 1020) {
            this.orientation = 'desktop';
        } else {
            this.orientation = 'mobile';
        }

        if (o !== this.orientation) {
            this.stopTimer();
            this.initTimer();
        }


    }


    initTimer() {

        this.timer.style.width = "";
        this.timer.style.height = "";


        if (!this.timerAnimation) this.timerAnimation = anime({
            targets: this.timer,
            duration: this.settings.timeout,
            easing: 'linear',
            height: () => {
                if (window.innerWidth > 1020) {
                    return ['0%', '100%']
                } else {
                    return false;
                }

            },
            width: () => {
                if (window.innerWidth < 1020) {
                    return ['0%', '100%']
                } else {
                    return false;
                }

            },
            complete: () => {
                this.timerAnimation.restart();
                this.animateResults();
            }
        });

    }


    animateResults(index) {

        // if (this.isAnimating) return false;
        // this.isAnimating = true;

        let currentVisual = this.visuals[this.current];
        let currentTitle = this.titles[this.current];
        let currentThumb = this.thumbs[this.current];


        if (index) {
            this.current = Number(index);
        } else {
            this.current = this.current < this.totalCases - 1 ? this.current + 1 : 0;
        }


        let newVisual = this.visuals[this.current];
        let newTitle = this.titles[this.current];
        let newThumb = this.thumbs[this.current];

        this.currentId = newTitle.dataset.id;

        currentThumb.classList.remove('is-active');
        newThumb.classList.add('is-active');
        newVisual.classList.add('is-active');
        newTitle.classList.add('is-active');


        anime({
            targets: currentVisual,
            duration: this.settings.animation.duration,
            easing: this.settings.animation.easing.in,
            opacity: [1, 0],
            complete: () => {
                currentVisual.classList.remove('is-active');
            }
        });

        anime({
            targets: currentTitle.children[0].children,
            duration: this.settings.animation.duration,
            easing: this.settings.animation.easing.out,
            opacity: [1, 0],
            complete: () => {
                if (this.currentId != currentTitle.dataset.id) {
                    currentTitle.classList.remove('is-active');
                }
            }
        });

        anime({
            targets: newVisual,
            duration: this.settings.animation.duration,
            easing: this.settings.animation.easing.in,
            opacity: [0, 1],
            translateX: [-50, 0]
        });

        anime({
            targets: newTitle.children[0].children,
            duration: this.settings.animation.duration,
            easing: this.settings.animation.easing.in,
            delay: (t, i, total) => i * 50 + 150,
            translateX: [-50, 0],
            opacity: [0, 1],
            complete: () => {
                this.isAnimating = false;
                //currentTitle.classList.add('is-active');
            }
        });


    }


    stopTimer() {
        this.timerAnimation = null;
        anime.remove(this.timer);
    }

    animateIn() {
        this.options.onAnimateInBegin();
        return new Promise((resolve) => {
            imagesloaded('.js-results-container', {background: true}, () => {

                this.loadImages();

                if (this.freshPopulation) {
                    texthelpers.spanWrapAllWords(this.titlesParent.querySelectorAll('h1'));
                    this.freshPopulation = false;
                }

                resolve();

                this.options.onAnimateInEnd();

            });
        });
    }


    animateOut() {
        this.options.onAnimateOutBegin();

        return new Promise((resolve) => {
            anime({
                targets: '.js-transition',
                duration: this.settings.animation.duration,
                easing: this.settings.animation.easing.in,
                translateX: ['-100%', 0],
                complete: () => {

                    this.options.onAnimateOutEnd();
                    resolve();
                }
            });
        });
    }


    pauseResults() {
        this.stopTimer();
        //this.animateOut();

    }


    resumeResults() {


        this.animateIn();
        this.initTimer();
        this.animateResults();

    }


    loadImages() {

        this.observer = lozad('.js-lozad', {
            loaded: function (el) {
                el.classList.add('is-loaded');
            }
        });


        this.observer.observe();
    }
}
