import * as localstorage from "./localstoragehelper";
import SYS from "../config";

let checkLocalstorage = (onLocalstorageAvailable) => {
    if (SYS.localstorageStore) {
        let _data = localstorage.get();

        if (_data && _data.selectors) {
            onLocalstorageAvailable(_data.selectors);
        }
    }
};

export {checkLocalstorage};