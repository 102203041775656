import anime from "animejs";
import $ from "jquery";

import TweenMax from "gsap/TweenMax";
import {Power3} from "gsap/EasePack";

export default class Navigation {
    constructor(el = ".js-nav", triggerEl = ".js-toggle-nav") {
        this.DOM = {};

        this.DOM.el = document.querySelector(el);
        this.DOM.triggerEl = document.querySelector(triggerEl);

        this.isOpen = false;
        this.isAnimating = false;

        this.settings = {
            animation: {
                duration: 800,
                easing: {
                    in: "easeInOutQuart",
                    out: "easeInOutQuart"
                }
            }
        };
    }
    init() {
        this.DOM.slices = Array.from(
            this.DOM.el.querySelectorAll(".js-nav-slice")
        );
        this.DOM.items = Array.from(
            this.DOM.el.querySelectorAll(".js-nav-item")
        );
        this.totalItems = this.DOM.items.length;

        this.initEvents();
    }

    initEvents() {

        let _this = this;

        this.DOM.triggerEl.addEventListener("click", () => {
            _this.isOpen ? _this.close() : _this.open();
        });

         Array.from(this.DOM.items).forEach((el) => {
            let link = el.querySelector('.js-nav-link');
            link.addEventListener("mouseenter", (e) => {

                if(_this.currentLink == e.target){
                    return false;
                }
                _this.currentLink = e.target;
                $('.js-nav-slice').removeClass('is-active');
                _this.DOM.slices[_this.currentLink.getAttribute("data-slice")].classList.add("is-active");

            });
        });



    }

    open() {

        if(this.isAnimating) return false;

        this.isAnimating = true;


         anime({
            targets: '.js-transition',
            duration: 800,
            easing: 'cubicBezier(.77, 0, .175, 1)',
            translateX: ['100%', 0],
            complete: () => {

            }
        });


        this.DOM.el.classList.remove("is-hidden");
        this.DOM.el.classList.add("is-visible");

        $('.js-nav-slice').removeClass('is-active');
        this.currentLink = null;



        this.isOpen = true;


        TweenMax.set(this.DOM.el, {
            opacity:   1
        });


        TweenMax.staggerFromTo(this.DOM.slices, .3,{
            skewX: 20,
            scaleX: 0
        },{
            skewX: 20,
            scaleX: 1,
            delay: .6,
            opacity: 1,
            ease: [0.77, 0, 0.175, 1]
        },.1);

        TweenMax.staggerFromTo(this.DOM.items, .3,{
            y: '5%',
            opacity: 0
        },{
            y: '0%',
            opacity: 1,
            delay: .8,
            ease: [0.77, 0, 0.175, 1],
            onComplete: () => {
                $('body').addClass('nav-is-visible');
                this.isAnimating = false;
            }
        },.1);










    }


     close() {


        if(this.isAnimating) return false;

        this.isAnimating = true;


        anime({
            targets: '.js-transition',
            duration: 800,
            easing: 'cubicBezier(.77, 0, .175, 1)',
            translateX: [0,'100%'],
            complete: () => {

            }
        });



         TweenMax.to('.js-view:not(.is-hidden)', .6, {
            opacity: 1,
            //x: 0,
            //scale: 1,
            ease: Power3.easeInOut
        });

        TweenMax.to('.js-view:not(.is-hidden) .js-view-content', .6, {
            opacity: 1,
            ease: Power3.easeInOut
        });


         TweenMax.to(this.DOM.el, .6, {
            opacity: 0,
            ease: Power3.easeInOut,
            onComplete: () => {

                this.isOpen = false;
                this.isAnimating = false;

                this.DOM.el.classList.add("is-hidden");
                this.DOM.el.classList.remove("is-visible");
                $('body').removeClass('nav-is-visible');
            }
        });



     }
}
