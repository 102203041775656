import {SELECT, RESET} from "./actionTypes";

const selectorReducer = (state = {},
                         action) => {
    switch (action.type) {
        case SELECT: {
            state = Object.assign(state, {
                [action.payload.type]: {
                    id: action.payload.id,
                    label: action.payload.label,
                    type:action.payload.type
                }
            });

            break;
        }

        case RESET: {

            state = {};
            break;
        }

        default:
            return state;
    }

    return state;
};

export {selectorReducer};
