import $ from "jquery";
import ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default class SceneFactory {

    createScene(settings, scrollParent) {

        const scene = new ScrollMagic.Scene({
            duration: '100%',
            triggerHook: 'onEnter'
        })
        .setTween(settings.setTween)
        .addTo(settings.addTo);

        scene.triggerElement($(settings.triggerElement)[0]);

        //scrollParent.options.scenes.push(scene);

        return scene;
    }


}