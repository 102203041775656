import anime from "animejs";
import $ from "jquery";
import ScrollMagic from "scrollmagic";
import TweenMax from 'gsap/TweenMax';

import View from '../../00_fundaments/View';

//import Scroll from "../../02_molecules/Scroll/Scroll";
import Video from "../../02_molecules/Video/Video";

import * as texthelpers from "../../00_helpers/texthelper";
import * as JobScenes from '../../00_helpers/jobscenes';
import SceneFactory from '../../00_helpers/scenefactory';
import Scroll from "../../02_molecules/Scroll/Scroll";

export default class Jobs extends View {

    constructor(options = {}) {

         options = Object.assign({
            containerSelector:'.js-view-jobs',
            videoSelector:'.js-play-video'

        }, options);

        super(options);

        this.TLscenes = [];
        this.SMscenes = [];

        this.el = document.querySelector(this.options.containerSelector);

        this.settings = {
            timeout: 10000,
            animation: {
                duration: 800,
                easing: {
                    in: 'easeOutQuint',
                    out: 'easeOutBack'
                }
            },
        }
    }

     init() {

        super.init();

        texthelpers.spanWrapAllWords(document.querySelectorAll('.js-jobs-block-text'));

        $('.js-scrollto-jobs').on('click', (e) => {

            e.preventDefault();

            $('html, body').stop().animate({
                scrollTop: $('.js-scene-jobs').eq(0).offset().top
            }, 500);

        });


        $('.js-contact-jobs').on('click', (e) => {
            e.preventDefault();

            $('html, body').stop().animate({
                scrollTop: $('.js-scene-contact').eq(0).offset().top
            }, 500);

        });


        $('.js-play-video').on('click', (e) => {

            this.createVideo(e.currentTarget.dataset.video);
            this.initVideo();
        });

        (function(that) {

            $(window).on('resize', function() {

                clearTimeout(that.resizeTimer);
                that.resizeTimer = setTimeout(function() { that.refreshScenes(); }, 200);

            });
        })(this);

    }

    refreshScenes() {

        this.SMscenes.forEach((scene, index) => {

            scene.refresh();

        });

    }

    async animateIn() {

        this.options.onAnimateInBegin();

        return new Promise((resolve) => {

            if(!this.sceneFactory)
            {

                this.sceneFactory = new SceneFactory();

                new Scroll();
                // this.scroll = new Scroll({
                //     element: '.js-view-jobs .js-scrollbar'
                // });
                this.controller = new ScrollMagic.Controller({
                    refreshInterval: 0
                });

                this.createScenes();
            }



            resolve();
            this.options.onAnimateInEnd();

        });


    }

    animateOut() {

        this.options.onAnimateOutBegin();

        return new Promise((resolve) => {
            anime({
                targets: '.js-transition',
                duration: this.settings.animation.duration,
                easing: this.settings.animation.easing.in,
                translateX: ['-100%', 0],
                complete: () => {

                    this.options.onAnimateOutEnd();
                    resolve();
                }
            });
        });
    }


    createScenes(){

        // Intro
        this.TLscenes.push({TimeLineScene: null, element: '.js-scene-intro'});

        //scenes
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene1(), element: '.js-scene-culture'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene2(), element: '.js-scene-agency'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene3(), element: '.js-scene-academy'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene4(), element: '.js-scene-soup'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene5(), element: '.js-scene-skisurf'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene6(), element: '.js-scene-ski'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene6b(), element: '.js-scene-surf'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene7(), element: '.js-scene-jobs'});
        this.TLscenes.push({TimeLineScene: JobScenes.TLscene8(), element: '.js-scene-contact'});

        /*  Create scenes and add to controller/Scroll  */
        var that = this;

        this.TLscenes.forEach((scene,index)=>{

            if(scene.TimeLineScene !== null){
                this.SMscenes.push(this.sceneFactory.createScene({
                    triggerElement : scene.element,
                    setTween: scene.TimeLineScene,
                    addTo: this.controller
                }));
            }

            // background visuals
            const currentScene = $(scene.element);

            if(currentScene.length) {

                const visual = currentScene.attr('data-visual');
                const s = new ScrollMagic.Scene({
                    duration: '200%',
                    triggerHook: 'onEnter'
                }).addTo(this.controller);

                s.triggerElement(currentScene[0]);

                s.on('enter', (e) => {
                    $('.' + visual).show();
                });

                s.on('leave', (e) => {
                    $('.' + visual).hide();
                });

                that.SMscenes.push(s);

            }

        });


    }

    createVideo(video){
        $('.js-video-overlay').html('<video class="js-video video-js" controls="" preload="auto" data-video="'+video+'"></video>');
    }


    initVideo(){


        this.video = new Video({
            element: '.js-video',
            parentElement: '.js-video-overlay',
            onReady: () => {
                $('.js-video-overlay').addClass('is-visible');

                TweenMax.to('.js-video-overlay', .5, {
                    opacity: 1
                });
            },
            onClose: () => {
                 TweenMax.to('.js-video-overlay', .5, {
                    opacity: 0,
                    onComplete: () => {
                        this.video.player.dispose();
                        $('.js-video-overlay').removeClass('is-visible');
                    }
                });
            }

        });


    }


}

