import TimelineMax from "gsap/TimelineMax";

import AnimationHelper from "./animationhelper";

const animationhelper = new AnimationHelper();

// export const TLscene0 = () => {
//     return  new TimelineMax()
//     .add(animationhelper.visualClipTween('.js-scene-facts-visual'), "-=.4")
//     .add(animationhelper.visualInTween('.js-scene-facts-visual-image'), "-=.5")
//     .add(animationhelper.visualOutTween('.js-scene-intro-visual-image'), "-=.5")
// };

export const TLscene1 = () => {
    return  new TimelineMax()
    
    .add(animationhelper.visualClipTween('.js-scene-fact1-visual'))
    .add(animationhelper.visualInTween('.js-scene-fact1-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-intro-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-fact1-slash'), "-=.2");
};

export const TLscene2 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-fact1-slash'))
    .add(animationhelper.visualClipTween('.js-scene-fact2-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-fact2-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-fact1-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-fact2-slash'), "-=.2");
};

export const TLscene3 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-fact2-slash'))
    .add(animationhelper.visualClipTween('.js-scene-fact3-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-fact3-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-fact2-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-fact3-slash'), "-=.2");
};

export const TLscene4 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-fact3-slash'))
    .add(animationhelper.visualClipTween('.js-scene-ecosystem-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-ecosystem-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-fact3-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-ecosystem-slash'), "-=.2");
};

export const TLscene5 = () => {
    return new TimelineMax()
    .add(animationhelper.slashInTween('.js-scene-offer-slash'), "+=.2")
    .add(animationhelper.visualOutTween('.js-scene-ecosystem-visual-image'), "-=.5");
};

export const TLscene6 = () => {
    return new TimelineMax()
    .add(animationhelper.slashInTween('.js-scene-contact-slash'));
};

