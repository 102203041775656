import {SELECT, RESET} from "./actionTypes";

const wizardReducer = (state = {
                           sector: null,
                           challenge: null,
                           showResults: false
                       },
                       action) => {
    switch (action.type) {
        case SELECT: {
            //log('SELECT action in wizardReducer',action.payload);

            state = Object.assign(state, {
                sector: action.payload.type == "sector" ? action.payload.id : state.sector,
                challenge: action.payload.type == "challenge" ? action.payload.id : state.challenge
            });

            if (state.sector && state.challenge) {
                state = Object.assign(state, {
                    showResults: true
                })
            }

            break;
        }

        case RESET: {
            state = Object.assign(state, {
                sector: null,
                challenge: null,
                showResults: false
            });
            break;
        }

        default:
            return state;
    }

    return state;
};

export {wizardReducer};