import imagesloaded from "imagesloaded";
import * as Cookies from "tiny-cookie";
import $ from "jquery";
import TweenMax from 'gsap/TweenMax';

import SYS from "../../config";

import {store} from "../../redux/store";
import {RESET} from "../../redux/actionTypes";

import * as localstorage from "../../00_helpers/localstoragehelper";
import * as systemhelper from "../../00_helpers/systemhelper";
import * as texthelpers from "../../00_helpers/texthelper";

export default class Ui {
    constructor(options) {
        this.options = Object.assign({
            onIntroComplete: () => {
            }
        }, options);
        this.name = "UI";
        this.triggerClass = "home";
        this.cookieMessage = $(".js-cookies-enabled");

        $("body").addClass(
            systemhelper.is_touch_device() == 0 ? "has-no-touch" : "has-touch"
        );


        
    }


    init() {

        const logo = document.querySelector('.js-logo');

        if(logo){
            logo.addEventListener('click', (e) => {
                store.dispatch({
                    type: RESET,
                    payload: {}
                });

                localstorage.set(store.getState());
                //window.location.reload();
                window.location.replace('/');
            });

        }


        imagesloaded('.js-preload', { background: true }, () => {
            $('.js-preload').removeClass('is-loading');
            $('.js-preload').addClass('is-loaded');


            if (Cookies.isEnabled()) {
                 this._manageCookies();
            }

         });

        document.addEventListener("touchstart", function(){}, true);


        // $('.js-view-fourohfour .js-block-text').each((index,value)=>{
        //     texthelpers.spanWrapAllWords(value);
        // });

        texthelpers.spanWrapAllWords($('.js-view-fourohfour .js-block-text'));


    }




    _manageCookies() {
        if (Cookies.getCookie(SYS.cookieStorageKey) === null) {
            // show message


            this.cookieMessage.show();
            TweenMax.from('.c-cookies', .5, {
                y: '100%',
                ease: 'Expo.easeOut',
                delay: 3,
                opacity: 0
            });
            this.cookieMessage.find(".js-cookies-accept").one("click", e => {
                e.preventDefault();
                Cookies.setCookie(SYS.cookieStorageKey, '1', {
                    expires: "1Y"
                });
                this.cookieMessage.hide();
            });


            /*this.cookieMessage.find(".js-cookies-refuse").one("click", e => {
                e.preventDefault();
                Cookies.setCookie(SYS.cookieStorageKey, '1', {
                    expires: "1Y"
                });
                this.cookieMessage.hide();
            });*/
        }
    }


}