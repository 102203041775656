import { createBrowserHistory } from "history";

class History{
    constructor(options = {}){

        this.listeners = [];
        this.history = createBrowserHistory();

        this.unlisten = this.history.listen((location, action) => {

            this.listeners.forEach((listener)=>{
                listener(location,action);
            })
        })

        this.actions = {
            PUSH:"PUSH",
            POP:"POP"
        }
    }

    goBack()
    {
        this.history.goBack();
    }

    pushState(path,data,title)
    {
        this.history.push(path,data);
        if(title)
        {
            document.getElementsByTagName('title')[0].innerHTML = title;
        }

    }
    addListener(listener){
        this.listeners.push(listener);
    }

}
export let history = new History();