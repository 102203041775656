import SYS from "../config";
import * as localStorage from "local-storage";

let set = params => {
    let _temp = Object.assign(get() || {}, params);
    localStorage.set(SYS.localStorageKey, _temp);
};

let get = () => localStorage.get(SYS.localStorageKey);

let remove = () => {
    localStorage.remove(SYS.localStorageKey);
};

let trackLocalStorage = newData => {

};

localStorage.on(SYS.localStorageKey, trackLocalStorage);

export {set, get, remove};
