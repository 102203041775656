import normalizeWheel from 'normalize-wheel';
import TweenLite from 'gsap/TweenLite';
import Draggable from 'gsap/Draggable';
import TweenMax from 'gsap/TweenMax';
import {Power1,Power4} from 'gsap/EasePack';
import $ from "jquery";

import SYS from "../../config";

import {store} from "../../redux/store";
import {SELECT} from "../../redux/actionTypes";

import * as localstorage from "../../00_helpers/localstoragehelper";

class Selector {
    constructor(options = {}) {
        this.options = Object.assign({
            trigger: ".js-trigger",
            element: ".js-selector",
            draggableElement: ".js-draggable-parent",
            type: "selector",
            onOpen: (e) => {
            },
            onClose: (e) => {
            },
            onSelectItem: (e) => {

            }

        }, options);

        this.isOpen = false;

        this.el = document.querySelector(this.options.element);

        if(this.el) {
            this.triggerEl = document.querySelectorAll(this.options.trigger);
            this.draggableList = this.el.querySelector(this.options.draggableElement);
            this.items = this.el.querySelectorAll('.c-selector__item');

            if (this.triggerEl && this.el) {
                this.init();
            }
        }
    }

    init() {

        this.initDraggable();

        let _this = this;

        this.el.addEventListener('mousewheel', function (event) {
            const normalized = normalizeWheel(event);


            TweenLite.to(_this.draggable[0].target, 0.25, {
                y: "-=" + normalized.pixelY,
                onUpdate: () => {
                    _this.draggable[0].applyBounds(this.el);
                },
                ease: Power1.easeOut
            });

        });


        Array.from(this.triggerEl).forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                this.open();
            });
        });

        Array.from(this.el.querySelectorAll(".js-selector-item")).forEach(
            item => {
                item.addEventListener("click", e => {
                    let _id = parseInt(e.currentTarget.getAttribute("data-id"), 10);

                    let _label = e.currentTarget.innerHTML;
                    this.selectItem(_id, _label);
                });
            }
        );

        store.subscribe(() => {
            this._onStoreStateChange();
        });


    }


    initDraggable() {
        let _this = this;

        this.draggable = Draggable.create(this.draggableList, {
            type: "y",
            bounds: this.el,
            edgeResistance: 0.8,
            throwProps: true,
            onClick: (e) => {
                e.preventDefault();
            }, onUpdate: (e) => {
                _this.draggable[0].applyBounds(this.el);
            }
            // snap: {
            //     y: function (endValue) {
            //         let gridHeight = window.innerHeight * 0.2;
            //         return Math.round(endValue / gridHeight) * gridHeight;
            //     }
            // }
        });
    }

    open(forceClose = false) {
        if (this.isOpen || forceClose) {
            this.el.classList.add("is-hidden");
            this.el.classList.remove("is-visible");
            document.body.classList.remove("selector-is-visible");
            this.isOpen = false;
        } else {
            this.isOpen = true;
        }
        this.isOpen ? this.options.onOpen() : this.options.onClose();
    }

    selectItem(id, label, isInitial = false) {
        let _this = this;
        store.dispatch({
            type: SELECT,
            payload: {
                type: _this.options.type,
                id: id,
                label: label
            }
        });
        if (!isInitial) {
            _this.options.onSelectItem(id, label);
        }
        if (this.isOpen) this.open(true);
    }

    _onStoreStateChange() {
        // get store state and trigger dom change on both selectors set
        let _selectorState = store.getState().selectors[this.options.type];

        //log(_selectorState);

        if (_selectorState) {

            $('.c-wizard-control').addClass('is-visible');

            setTimeout(() => {
                $('.c-wizard-control').addClass('is-active');
            }, 500);

            Array.from(this.triggerEl).forEach((el) => {
                el.innerHTML = _selectorState.label;
            });
            if (SYS.localstorageStore) {

                localstorage.set(store.getState());
            }
        }
    }


    updateScrollable(e) {


        e.preventDefault();

    }


    animateIn() {

        this.el.classList.remove("is-hidden");
        this.el.classList.add("is-visible");
        document.body.classList.add("selector-is-visible");


        TweenMax.staggerFrom(this.items, 1, {
            y: '100%',
            opacity: 0,
            ease: Power4.easeOut,
            onComplete: () => {
                this.el.classList.add("is-ready");
            }
        }, 0.05);



    }
}

export default Selector;
