import "core-js";
import 'core-js/features/array/includes';
import 'core-js/features/string/includes';
import "regenerator-runtime/runtime";

// Import components

import Selector from './02_molecules/Selector/Selector';

import Navigation from './03_organisms/Navigation/Navigation';
import Slash from './03_organisms/Slash/Slash';
import CaseDetail from './03_organisms/CaseDetail/CaseDetail';
import Matrix from './03_organisms/Matrix/Matrix';
import Cases from './03_organisms/Cases/Cases';
import Home from './03_organisms/Home/Home';
import About from './03_organisms/About/About';
import Contact from './03_organisms/Contact/Contact';
import Reputation from './03_organisms/Reputation/Reputation';
import Jobs from './03_organisms/Jobs/Jobs';
import JobDetail from './03_organisms/JobDetail/JobDetail';
import Ui from './03_organisms/Ui/Ui';

import {store} from './redux/store';
import * as gtmhelper from './00_helpers/gtmhelper';
import * as selectorhelper from './00_helpers/selectorhelper';


// Initialize application
const App = () => {


    const bodyEl = document.getElementsByTagName('body')[0];
    const bodyClassList = bodyEl.classList;

    // instantiate classes

    const navigation = new Navigation('.js-nav', '.js-toggle-nav');

    const slash = new Slash({
        elementSelector: "body",
        onAnimateInBegin: () => {
            if (bodyClassList.contains('site')) {
                home.freezeVideo();
            }
        },
        onAnimateInEnd: () => {
            if (bodyClassList.contains('site')) {
                home.unfreezeVideo();
            }
        },
        onAnimateOutBegin: () => {
            if (bodyClassList.contains('site')) {
                home.freezeVideo();
            }
        },
        onAnimateOutEnd: () => {
            if (bodyClassList.contains('site')) {
                home.unfreezeVideo();
            }
        }
    });


    const caseDetail = new CaseDetail({
        name: 'caseDetail',
        usesHistory: true,
        path: /^\/cases\/([0-9]+)\/[0-9a-z-]+\/?$/
    });


    const matrix = new Matrix({
        name: 'matrix',
        usesHistory: true,
        path: /^\/$/,
        onCaseLoaded: (data) => {
            matrix.pauseResults();
            window.location = '/cases/' + data.id + '/' + data.slug + '/';
        }
    });


    const cases = new Cases({
        name: 'cases',
        containerSelector: '.js-view-cases',
        usesHistory: true,
        path: /^\/cases\/?$/
    });


    const home = new Home();
    const about = new About();
    const contact = new Contact();
    const reputation = new Reputation();
    const jobs = new Jobs({
        name: 'jobs',
        containerSelector: '.js-view-jobs',
        usesHistory: true,
        path: /^\/jobs\/?$/
    });


    const jobDetail = new JobDetail({
        name: 'jobDetail',
        usesHistory: true,
        path: /^\/jobs\/([0-9]+)\/[0-9a-z-]+\/?$/
    });

    const ui = new Ui();


    /**
     *
     * INIT FROM HERE
     *
     */


    const init = () => {


        ui.init();
        navigation.init();

        if (window.location.pathname === "/") {
            slash.init();
            matrix.init();
            home.init();
        }

        if (window.location.pathname === '/cases') {
            cases.init();
            slash.init();
            initClientSelector();
        }

        jobDetail.init();
        caseDetail.init();

        if (window.location.pathname === '/about') {
            about.init();
        }

        if (window.location.pathname === '/contact') {
            contact.init();
        }

        if (window.location.pathname === '/reputation') {
            reputation.init();
        }

        if (window.location.pathname === '/jobs') {
            jobs.init();
        }


        if (window.location.pathname === "/") {
            home.animateIn();
            initSelectors();
        }

    }

    const initSelectors = () => {

        const sectorSelector = new Selector({
                trigger: ".js-toggle-sector",
                element: ".js-selector-sector",
                type: 'sector',
                onOpen: () => {

                    slash.animateIn({
                        onSlashComplete: () => {
                            sectorSelector.animateIn();
                        }
                    });
                },
                onClose: () => {

                    slash.animateOut();
                    //challengeSelector.open();
                },
                onSelectItem: (id, label) => {
                    var _sector = store.getState().selectors.sector ? store.getState().selectors.sector.label : false;
                    var _challenge = store.getState().selectors.challenge ? store.getState().selectors.challenge.label : false;
                    if (_sector && _challenge) {
                        gtmhelper.event("matrix", _sector, _challenge);
                    }
                }
            }
        );

        const challengeSelector = new Selector({
                trigger: ".js-toggle-challenge",
                element: ".js-selector-challenge",
                type: 'challenge',
                onOpen: () => {

                    slash.animateIn({
                        onSlashComplete: () => {
                            challengeSelector.animateIn();
                        }
                    });
                },
                onClose: () => {

                    slash.animateOut();
                },
                onSelectItem: (id, label) => {
                    var _sector = store.getState().selectors.sector ? store.getState().selectors.sector.label : false;
                    var _challenge = store.getState().selectors.challenge ? store.getState().selectors.challenge.label : false;
                    if (_sector && _challenge) {
                        gtmhelper.event("matrix", _sector, _challenge);
                    }
                }
            }
        );

        selectorhelper.checkLocalstorage((data) => {
            if (data.sector) {
                sectorSelector.selectItem(data.sector.id, data.sector.label, true);
            }
            if (data.challenge) {
                challengeSelector.selectItem(data.challenge.id, data.challenge.label, true);
            }
        });


    }

    const initClientSelector = () => {

        const clientsSelector = new Selector({
                trigger: ".js-toggle-clients",
                element: ".js-selector-clients",
                type: 'clients',
                onOpen: () => {

                    slash.animateIn({
                        onSlashComplete: () => {
                            clientsSelector.animateIn();
                        }
                    });
                    cases.slider.allowScroll = false;

                },
                onClose: () => {

                    slash.animateOut();
                    cases.slider.allowScroll = true;
                },
                onSelectItem: (id, label) => {
                    gtmhelper.event("clients", gtmhelper.ACTIONS.FILTER, label);
                }
            }
        );


        selectorhelper.checkLocalstorage((data) => {
            if (data.clients) {
                clientsSelector.selectItem(data.clients.id, data.clients.label, true);
            }

        });


    }

    init();
};

// Add event listener
window.addEventListener('load', App);