let pageview = function (page) {
    if(window._gtm) window._gtm("send", "pageview", page);
};
let event = function(category,action,label,value = null){
    if(window._gtm) window._gtm("send", "event", category, action, label, value);
};

const ACTIONS = {
    CLICK:'click',
    PLAY:'play',
    FILTER:'filter'
};

export {pageview,event,ACTIONS}

