import Scrollbar from 'smooth-scrollbar';
import ScrollMagic from "scrollmagic";
import $ from "jquery";

import * as texthelpers from "../../00_helpers/texthelper";
import Scroll from "../../02_molecules/Scroll/Scroll";

import * as ReputationScenes from '../../00_helpers/reputationscenes';
import SceneFactory from '../../00_helpers/scenefactory';

export default class Reputation {

    constructor() {
        this.TLscenes = [];
    }

    init() {

        let _this = this;

        this.SceneFactory = new SceneFactory();

        this.scroll = new Scroll();


        this.controller = new ScrollMagic.Controller({
            refreshInterval: 0
        });

        this.createScenes();

        texthelpers.spanWrapAllWords(document.querySelectorAll('.js-block-text'));

    }


    createScenes() {

// Intro
        this.TLscenes.push({TimeLineScene: null, element: '.js-scene-intro'});

//scenes
//this.TLscenes.push({TimeLineScene: ReputationScenes.TLscene0(), element: '.js-scene-facts'});
        this.TLscenes.push({TimeLineScene: ReputationScenes.TLscene1(), element: '.js-scene-fact1'});
        this.TLscenes.push({TimeLineScene: ReputationScenes.TLscene2(), element: '.js-scene-fact2'});
        this.TLscenes.push({TimeLineScene: ReputationScenes.TLscene3(), element: '.js-scene-fact3'});
        this.TLscenes.push({TimeLineScene: ReputationScenes.TLscene4(), element: '.js-scene-ecosystem'});
        this.TLscenes.push({TimeLineScene: ReputationScenes.TLscene5(), element: '.js-scene-offer'});
        this.TLscenes.push({TimeLineScene: ReputationScenes.TLscene6(), element: '.js-scene-contact'});


        /*  Create scenes and add to controller/Scroll  */

        this.TLscenes.forEach((scene, index) => {

            if (scene.TimeLineScene !== null) {
                this.SceneFactory.createScene({
                    triggerElement: scene.element,
                    setTween: scene.TimeLineScene,
                    addTo: this.controller
                }, this.scroll)
            }

            // background visuals
            const currentScene = $(scene.element);
            const visual = currentScene.attr('data-visual');
            const s = new ScrollMagic.Scene({
                triggerElement: currentScene,
                duration: '200%',
                triggerHook: 'onEnter'
            }).addTo(this.controller);

            s.on('enter', (e) => {
                $('.' + visual).show();
            });

            s.on('leave', (e) => {
                $('.' + visual).hide();
            });

        });

    }


}

