import ScrollMagic from "scrollmagic";
import TimelineMax from 'gsap/TimelineMax';
import TweenMax from 'gsap/TweenMax';
import {Power0} from 'gsap/EasePack';
import $ from "jquery";

import * as texthelpers from "../../00_helpers/texthelper";
import Scroll from "../../02_molecules/Scroll/Scroll";
import AnimationHelper from "../../00_helpers/animationhelper";



export default class Contact {

    constructor() {
        this.SMscenes = [];
    }

     init() {

        let _this = this;

        this.scroll = new Scroll();

        this.animationhelper = new AnimationHelper();

        this.controller = new ScrollMagic.Controller({
            refreshInterval: 0
        });

        this.createScenes();

        texthelpers.spanWrapAllWords(document.querySelectorAll('.js-block-text'));


        $('.js-contact-info').on('click', (e) => {
            e.preventDefault();

            $('html, body').stop().animate({
                scrollTop: $('.js-scene-contact').eq(0).offset().top
            }, 500);

        });

        (function(that) {

            $(window).on('resize', function() {

                clearTimeout(that.resizeTimer);
                that.resizeTimer = setTimeout(function() { that.refreshScenes(); }, 200);

            });
        })(this);

    }

    refreshScenes() {

        this.SMscenes.forEach((scene, index) => {

            scene.refresh();

        });

    }


    createScenes(){

        let _this = this;

        this.controller = new ScrollMagic.Controller({
            refreshInterval: 0
        });


        /**
         *
         * Scene 1
         *
         */

        let TLscene1 = new TimelineMax();

        let insightTween = TweenMax.fromTo('.js-scene-insight-slash', .5, {
            x: '-100%',
        },{
            x: '0%',
            ease: Power0.easeNone
        });

        TLscene1.add(insightTween);


        let darkenTween = TweenMax.to('.js-scene-darken', .5, {
            opacity: 1,
            ease: Power0.easeNone
        });

        TLscene1.add(darkenTween, "-=.5");
        TLscene1.add(_this.animationhelper.visualOutTween('.js-scene-intro-visual-image'), "-=.5");


        this.scene1 = new ScrollMagic.Scene({
            triggerElement: '.js-scene-contact',
            duration: '100%',
            triggerHook: 'onEnter'
        })
        .setTween(TLscene1)
        .addTo(_this.controller);

        this.SMscenes.push(this.scene1);


    }


}

