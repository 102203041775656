import OdoObjectFit from '@odopod/odo-object-fit';
import $ from "jquery";

import anime from "animejs";

export default class Home {
    constructor(options) {
        this.options = Object.assign({
            videoSelector: '.js-home-video',
            elementSelector: '.js-view-home',
            canvasSelector: '.js-home-canvas',
            onIntroComplete: () => {
            }
        }, options);

        this.name = "Home";
        this.el = document.querySelector(this.options.elementSelector);

        this.settings = {
            timeout: 10000,
            animation: {
                duration: 800,
                easing: {
                    in: 'easeOutQuint',
                    out: 'easeOutBack'
                }
            },
        }
    }


    init() {
        //const s = new share();

        this.video = document.querySelector(this.options.videoSelector);
        this.canvas = document.querySelector(this.options.canvasSelector);


        // Polyfill for cover video elements in IE
        OdoObjectFit.cover(this.video);

    }

    animateIn() {
        this.el.classList.remove('is-hidden');

        anime({
            targets: this.el,
            duration: this.settings.animation.duration,
            easing: this.settings.animation.easing.in,
            delay: 1000,
            opacity: [0, 1],
            complete: () => {
                this.animateDisruptionTitle();
            }

        })
    }


    animateDisruptionTitle() {
        

        $('.js-home-intro').removeClass('is-hidden');



            setTimeout(() => {


                $('.js-home-intro').addClass('is-hidden');  
                $('.js-home-content').removeClass('is-hidden');
                this.options.onIntroComplete();

                
    
                anime({
                    targets: '.js-home-anim',
                    translateY: [50,0],
                    opacity: [0,1],
                    duration: 600,
                    easing: 'easeOutQuint',
                    delay: (t, i, total) => i * 50 + 150

                })

                

            }, 1500);
    }

    freezeVideo() {
        this.video.pause();
        this.canvas.setAttribute('width',window.innerWidth);
        this.canvas.setAttribute('height',window.innerHeight);
        let _context = this.canvas.getContext('2d');

        let _x = 0;
        let _y = 0;
        let _width = window.innerWidth;
        let _height = window.innerHeight;

        if(_width / _height > 1.78)
        {
            //image is wider
            let _calculatedHeight = _width / 16 * 9;

            _y = (_calculatedHeight - _height) * -0.5;
            _height = _calculatedHeight;
        }
        else
        {
            let _calculatedWidth = _height / 9 * 16;

            _x = (_calculatedWidth - _width) * -0.5;
            _width = _calculatedWidth;
        }

        _context.drawImage(this.video, _x,_y,_width,_height);
        this.video.classList.add('is-hidden');

    }

    unfreezeVideo() {
        this.video.classList.remove('is-hidden');
        let _context = this.canvas.getContext('2d');
        _context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.video.play();
    }


}
