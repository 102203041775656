import Scrollbar from 'smooth-scrollbar';
import $ from "jquery";

class Scroll {
    constructor(options = {}) {

        // define variables
        this._scrollPos = 0;
        this._scrollDirection = false;

        // set initial scroll state
        this.checkOffset();

        // bind events
        (function(that) { $(window).bind('scroll', function() { that.checkOffset(); }); })(this);

        $('.js-scroll-down').on('click', () => {

            $('html, body').stop().animate({
                scrollTop: $('.js-scene-scrolldownto').eq(0).offset().top
            }, 500);

        });

    }

    checkOffset() {

        // define temp pos
        var _sPos = $(window).scrollTop();

        // define direction
        this._scrollDirection = (_sPos > this._scrollPos) ? 'down' : 'up';

        // store current scroll position
        this._scrollPos = _sPos;

        // perform some checks
        if(this._scrollPos > 1 && this._scrollDirection === 'down') { $('body').addClass('nav-is-hidden'); }
        else { $('body').removeClass('nav-is-hidden'); }

    }


    initScrollbar() {

        this.scrollbar = Scrollbar.init(document.querySelector(this.options.element), {
            damping: 0.05,
            overscrollEffect: 'bounce'
        });


    }


    initEvents() {
        let _this = this;
        this.scrollbar.addListener((status) => {


            _this.options.scenes.forEach((scene) => {
                scene.refresh();
            });

            if (status.offset.y > 20) {
                $('.js-show-insight').removeClass('is-visible');
            } else {
                $('.js-show-insight').addClass('is-visible');
            }

            if (this.direction === status.direction.y) {
                if (status.direction.y === 'up') {

                    if (status.offset.y < this.tolerance || this.toleranceExceeded(status.offset.y)) {
                        $('body').removeClass('nav-is-hidden');
                    }
                } else {
                    $('body').addClass('nav-is-hidden');
                    this.lastKnownScrollY = status.offset.y;
                }
                return false;
            }
            this.direction = status.direction.y;


        });


    }


    toleranceExceeded(currentScrollY) {
        return Math.abs(currentScrollY - this.lastKnownScrollY) >= this.tolerance;
    }


}

export default Scroll;