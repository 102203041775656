import normalizeWheel from 'normalize-wheel';
import $ from "jquery";
import TweenLite from 'gsap/TweenLite';
import {Power1} from 'gsap/EasePack';
import Draggable from "gsap/Draggable";


export default class Slider {
    constructor(options = {}) {
        this.options = Object.assign({
            element: '.js-slider',
            items: '.js-slider-items',
            item: '.js-slider-item',
            scrollbar: '.js-slider-scrollbar',
            knob: '.js-slider-knob',
            filterParameter:'data-filter',
            onItemSelect: () => {
            }


        }, options);


        let _this = this;
        this.filterId = null;
        this._container = $(this.options.element)[0];
        this.allowScroll = false;
        this.initSlider();
        // re-initialize the slider when the window is resized
        window.addEventListener('resize', function () {
            // TODO: add debounce
            _this.update(_this.filterId);
        });

    }

    /**
     * Initialize the slider
     * @public
     */
    initSlider() {
        let _this = this;
        this.setSliderValues();



        // initiate scrollbar when available
        if (this._container.querySelector(this.options.knob) != null) {
            this.initScrollbar();
        }

        // return when the slider fits in the content-wrapper
        if (this._draggableListWidth <= this._container.outerWidth) {
            return;
        }


        // create slider object
        this._draggableSliderObject = Draggable.create(this._draggableList, {
            type: 'x',
            edgeResistance: 0.75,
            bounds: this._draggableList.parentElement,
            zIndexBoost: false,
            dragClickables: true,
            cursor: 'ew-resize',
            throwProps: true,
            onClick: function (e) {
                //e.preventDefault();
            },
            snap: {
                x: function (endValue) {
                    return Math.round(endValue / this._gridWidth) * this._gridWidth;
                }.bind(this)
            },
            onDrag: function () {
                _this.updateKnobPosition(_this._draggableSliderObject.x);
            },
            onThrowUpdate: function () {
                _this.updateKnobPosition(_this._draggableSliderObject.x);
            }
        })[0];

        /*
        Array.from(this._container.querySelectorAll(_this.options.item)).forEach(
            item => {
                item.addEventListener("click", e => {
                    e.preventDefault();
                    let _id = parseInt(e.currentTarget.getAttribute("data-id"), 10);
                    let _slug = e.currentTarget.getAttribute("data-slug");
                    _this.options.onItemSelect(_id,_slug);
                });

            }
        );
        */

        _this.allowScroll = true;


        // Mouse wheel events

         window.addEventListener('mousewheel', function (event) {

            const normalized = normalizeWheel(event);

            if(_this.allowScroll === false) return false;

            TweenLite.to(_this._draggableSliderObject.target ,0.25,{
                x:"-=" + normalized.pixelY * 1.25,
                onUpdate: () => {
                     _this._draggableSliderObject.applyBounds(this._draggableSliderObject);
                     _this.updateKnobPosition(_this._draggableSliderObject.x);
                },
                ease : Power1.easeOut
          });

        });


    }

    matrixToArray(matrix) {
        return matrix.substr(7, matrix.length - 8).split(', ');
    }


    getAdjustedWidth(skewedObj) {
        let jqElement = $(skewedObj);
        let origWidth = jqElement.width();
        let origHeight = jqElement.height();
        let matrix = this.matrixToArray(jqElement.css('transform'));
        let alpha = matrix[1];
        let adjusted = Math.sin(alpha) * origHeight / Math.sin(Math.PI / 2 - alpha);
        return origWidth + Math.abs(adjusted);
    }


    /**
     * Set the values for the slider
     * @private
     */
    setSliderValues(id) {

        this._draggableList = this._container.querySelector(this.options.items);
        if (id) {
            this._draggableItems = this._container.querySelectorAll(this.options.item + '['+this.options.filterParameter+'="' + id + '"]');
        } else {
            this._draggableItems = this._container.querySelectorAll(this.options.item);
        }
        //this._draggableItemWidth = this._draggableItems[0].getBoundingClientRect().width;

        if(this._draggableItems.length > 0) {

            this._draggableItemWidth = this.getAdjustedWidth(this._draggableItems[0]);

            this._draggableItemGutter = parseInt(getComputedStyle(this._draggableItems[0]).marginRight) + parseInt(getComputedStyle(this._draggableItems[0]).marginLeft);
            this._gridWidth = this._draggableItemWidth + this._draggableItemGutter;
            this._draggableListWidth = this._draggableItems.length * this._gridWidth - this._draggableItemGutter;
            this._draggableList.style.width = this._draggableListWidth + 'px';
        }

    }

    /**
     * Initialize the custom scrollbar
     * @private
     */
    initScrollbar() {
        let _this = this;
        this.setScrollbarValues();
        // create scrollbar object
        this._draggableScrollbarObject = Draggable.create(this._draggableKnob, {
            type: 'x',
            edgeResistance: 1,
            throwResistance: 10000,
            bounds: this._draggableScrollbar,
            zIndexBoost: false,
            cursor: 'ew-resize',
            throwProps: true,
            onDrag: function () {
                _this.updateListPosition(_this._draggableScrollbarObject.x);
            },
            onThrowUpdate: function () {
                _this.updateListPosition(_this._draggableScrollbarObject.x);
            }
        })[0];
    }

    /**
     * Set the values for the scrollbar
     * @private
     */
    setScrollbarValues() {
        this._draggableScrollbar = this._container.querySelector(this.options.scrollbar);
        this._draggableKnob = this._container.querySelector(this.options.knob);
        this._draggableScrollbarWidth = this._container.offsetWidth;
        this._draggableKnobWidth = this._draggableScrollbarWidth / this._draggableListWidth * this._draggableScrollbarWidth;
        this._draggableKnob.style.width = this._draggableKnobWidth + 'px';
        // hide the scrollbar when all listitems fit in the content-wrapper
        if (this._draggableListWidth <= this._draggableScrollbarWidth) {
            this._draggableScrollbar.style.opacity = 0;
        } else {
            this._draggableScrollbar.style.opacity = 1;
        }
        // add click eventlistener to scrollbar
        this._draggableScrollbar.addEventListener('click', this.handleScrollbarClick.bind(this));
    }

    /**
     * Scroll to the left or right when the scrollbar is clicked
     * @param {MouseEvent} event
     * @private
     */
    handleScrollbarClick(event) {
        let _this = this;
        // do nothing when we click the knob
        if (event.target !== this._draggableScrollbar) {
            return;
        }
        let clickPosition;
        let knobMinX;
        let knobMaxX;
        let scrollToPosition;
        // get click position and knob min/max x-position
        if (getComputedStyle(this._draggableScrollbar).direction == 'ltr') {
            clickPosition = event.clientX - this._draggableScrollbar.getClientRects()[0].left;
            knobMinX = 0;
            knobMaxX = this._draggableScrollbarWidth - this._draggableKnobWidth;
            scrollToPosition = clickPosition - (this._draggableKnobWidth / 2);
        } else {
            clickPosition = event.clientX - this._draggableScrollbar.getClientRects()[0].right;
            knobMinX = this._draggableKnobWidth - this._draggableScrollbarWidth;
            knobMaxX = 0;
            scrollToPosition = clickPosition + (this._draggableKnobWidth / 2);
        }
        // keep scrollToPosition within bounds
        scrollToPosition = Math.max(knobMinX, Math.min(scrollToPosition, knobMaxX));
        // Tween the knob to the new position
        TweenLite.to(this._draggableKnob, .5, {
            x: scrollToPosition,
            ease: Power1.easeInOut,
            onUpdate: function () {
                _this.updateListPosition((_this._draggableKnob)._gsTransform.x);
            }
        });
    }

    /**
     * Update scrollbar knob position
     * @private
     */
    updateKnobPosition(scrollPosition) {
        if (this._draggableKnob != null) {
            let percentMoved = (scrollPosition / (this._draggableListWidth - this._draggableScrollbarWidth)) * 100;
            let pixelsMoved = Math.round((this._draggableScrollbarWidth - this._draggableKnobWidth) * percentMoved / 100);
            TweenLite.set(this._draggableKnob, {
                x: pixelsMoved * -1
            });
        }
    }

    /**
     * Update draggable list position
     * @private
     */
    updateListPosition(scrollPosition) {
        let percentMoved = (scrollPosition / (this._draggableScrollbarWidth - this._draggableKnobWidth)) * 100;
        let pixelsMoved = Math.round((this._draggableListWidth - this._draggableScrollbarWidth) * percentMoved / 100);
        TweenLite.set(this._draggableList, {
            x: pixelsMoved * -1
        });
    }


    update(id) {

        this.filterId = id;

        // and show the ones that are filtered -> only if id is set and not null;
        if (id) {
            $(this.options.item).hide();
            $(this.options.item + '['+this.options.filterParameter+'="' + id + '"]').show();
        }
        else
        {
            $(this.options.item).show();
        }

        this.setSliderValues(id);
        this._draggableSliderObject.update(true);
        this.setScrollbarValues();
        this._draggableScrollbarObject.update(true);
    }


}