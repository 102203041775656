import TimelineMax from 'gsap/TimelineMax';
import AnimationHelper from "./animationhelper";

const animationhelper = new AnimationHelper();

export const TLscene2 = () => {
    return new TimelineMax()
        .add(animationhelper.visualClipTween('.js-scene-slashies-visual'))
        .add(animationhelper.visualInTween('.js-scene-slashies-visual-image'), "-=.5")
        .add(animationhelper.visualOutTween('.js-scene-slashies-slash'), "-=.2")
};
