import TimelineMax from "gsap/TimelineMax";

import AnimationHelper from "./animationhelper";

const animationhelper = new AnimationHelper();

export const TLscene0 = () => {
    return  new TimelineMax()
    .add(animationhelper.visualClipTween('.js-scene-AOTY-video'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-AOTY-video-video'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-intro-visual-image'), "-=.5")
};

export const TLscene1 = () => {
    return  new TimelineMax()
    .add(animationhelper.visualClipTween('.js-scene-disruption-visual'))
    .add(animationhelper.visualInTween('.js-scene-disruption-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-AOTY-video-video'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-disruption-slash'), "-=.2");
};

export const TLscene2 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-disruption-slash'))
    .add(animationhelper.visualOutTween('.js-scene-disruption-visual-image'), "-=.5")
    .add(animationhelper.visualClipTween('.js-scene-quote-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-quote-visual-image'), "-=.5");
};

export const TLscene3 = () => {
    return new TimelineMax()
    .add(animationhelper.visualClipTween('.js-scene-creativity-visual'))
    .add(animationhelper.visualInTween('.js-scene-creativity-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-quote-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-creativity-slash'), "-=.2");
};

export const TLscene4 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-creativity-slash'))
    .add(animationhelper.visualClipTween('.js-scene-culture-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-culture-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-creativity-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-culture-slash'), "-=.2");
};

export const TLscene5 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-culture-slash'))
    .add(animationhelper.visualClipTween('.js-scene-disruptionlive-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-disruptionlive-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-culture-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-disruptionlive-slash'), "-=.2");
};

export const TLscene6 = () => {
    return new TimelineMax()
    .add(animationhelper.slashOutTween('.js-scene-disruptionlive-slash'))
    .add(animationhelper.visualOutTween('.js-scene-disruptionlive-visual-image'), "-=.4")
    .add(animationhelper.visualClipTween('.js-scene-quote-2-visual'), "-=.4")
    .add(animationhelper.visualInTween('.js-scene-quote-2-visual-image'), "-=.5");
};

export const TLscene7 = () => {
    return new TimelineMax()
    .add(animationhelper.visualClipTween('.js-scene-backslash-visual'))
    .add(animationhelper.visualInTween('.js-scene-backslash-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-quote-2-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-backslash-slash'), "-=.2");
};

export const TLscene8 = () => {
    return new TimelineMax()
    .add(animationhelper.visualOutTween('.js-scene-backslash-visual-image'))
    .add(animationhelper.slashInTween('.js-scene-tbwa-slash'), "-=.5");
};

export const TLscene9 = () => {
    return new TimelineMax()
    .add(animationhelper.visualClipTween('.js-scene-facts-visual'))
    .add(animationhelper.visualInTween('.js-scene-facts-visual-image'), "-=.5")
    .add(animationhelper.visualOutTween('.js-scene-backslash-visual-image'), "-=.5")
    .add(animationhelper.slashInTween('.js-scene-facts-slash'), "-=.2");
};

export const TLscene10 = () => {
    return new TimelineMax()
    .add(animationhelper.slashInTween('.js-scene-clients-slash'));
};

export const TLscene11 = () => {
    return new TimelineMax()
    .add(animationhelper.slashInTween('.js-scene-contact-slash'));
};