import SYS from "../config";

// GLOBAL HELPERS//////////////////////////

// use log as function for logging
window.log = (function() {
	if (SYS.DEBUG) {
		if (!window.console || !console.log) {
			return;
		}
		return Function.prototype.bind.call(console.log, console);
	}
    else{
        return Function.prototype;
    }
})();

// EXPORTABLE HELPERS//////////////////////////

let is_touch_device = () =>
	"ontouchstart" in window || navigator.maxTouchPoints;

export { is_touch_device };
